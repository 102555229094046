<template>
  <figure class="media">
    <div class="media-container">
      <UiImage
        v-if="finalImage && !finalVideo && !props.externalVideo"
        v-bind="finalImage"
      />
      <UiVideo v-if="finalVideo" v-bind="finalVideo" :poster="finalImage" />
      <ExternalVideo
        v-if="props.externalVideo"
        v-bind="props.externalVideo"
        :poster="finalImage"
      />
    </div>
    <figcaption v-if="legend" class="_legend">{{ legend }}</figcaption>
  </figure>
</template>
<script setup lang="ts">
import ExternalVideo from '~/components/ui/externalVideo/externalVideo.vue'
import type { TImage } from '~/components/ui/image/image.type.js'
import UiImage from '~/components/ui/image/image.vue'
import type { TVideo } from '~/components/ui/video/video.type.js'
import UiVideo from '~/components/ui/video/video.vue'
import './media.css'
import { type TMedia } from './media.type.js'

const props = withDefaults(defineProps<TMedia>(), {})

const finalVideo = props.video as TVideo
if (finalVideo) {
  finalVideo.advanced = props.advanced
}

const finalImage = props.image as TImage
if (finalImage) {
  finalImage.advanced = props.advanced
}
</script>
